import styled from 'styled-components'
import { currentTheme, testTheme, theme } from '../../Styles'
import { theme2024, waxfusionTheme } from '../../Styles2024'

export const FooterCont = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    max-height: auto;
    margin-left: 0px; 
    margin-right: 0px;
    //background-color: ${theme2024.backgroundDark};
    background: rgb(156,207,225);
    background: -moz-radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    background: -webkit-radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    background: radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9ccfe1",endColorstr="#74b2d1",GradientType=1);    
    box-shadow: 0px 0px 2px 0.5px ${theme.textSecondary};
    flex-wrap: wrap;

    //border-top: 1px solid dimgray;
    padding-bottom: 1em;
    padding-left: 1em;

    color: white;

    a{
        :hover{
            color: ${waxfusionTheme.darkBlue}
        }
    }

    @media (max-width: 648px) {
        display: block;
    }

`

export const SocialIconsDiv = styled.div`
    width: 100%;
    padding-top: 40px;
    padding-right: 70px;
    padding-bottom: 20px;
    
    display: flex;
    justify-content: right;
    gap: 20px;
    color: ${theme2024.primary};

    svg{
        fill: ${waxfusionTheme.darkBlue};
        //opacity: 60%;
        width: 20px;
        height: 20px;
        transition: fill 0.3s;

        :hover{
            fill: ${theme2024.textMain}
            //opacity: 100%;
            cursor: pointer;
        }
    }
`