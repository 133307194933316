import React from "react";
import {
  FolderTab2024,
  FoldersContainer2024,
  HeaderCont2024,
  PageBody2024,
  PageWrapper2024,
} from "../Styles2024";
import {
  ClickableP,
  FoldersRowCentered,
  FusionInputWrapper,
  MessageWrapper,
  ShowPriceContainer,
  SpaceBetweenDiv,
  StakeContainer,
} from "../FusionStyles";
import { useState } from "react";
import { useEffect } from "react";
import { isLoggedIn } from "../data/functions/wallet_functions";
import { useStateContext } from "../contexts/ContextProvider";
import { logInWithWharfkit } from "../data/wharfkit";
import { rentCpu } from "../data/functions/fusion_functions";
import { currentUsername } from "../data/config";
import { showWaxBalance } from "./Stake";
import config from "../data/config.json";
import { showRentalCostPercentage } from "./LongTermRent";
import { useGetMyShortTermRentals } from "../components/CustomHooks/useGetMyShortTermRentals";
import { useGetFusionState } from "../components/CustomHooks/useGetFusionState";
import TransactionModal from "../components/TransactionModal";

export const sortMethods = [
  "Ending Soonest",
  "Ending Latest",
  "Receiver a-z",
  "Receiver z-a",
  "Most WAX",
  "Least WAX"
]

export const handleSortingRentals = (e, rentals, setRentals) => {
  let sortedRentalArray = [...rentals];

  if(e.target.value == "Ending Soonest"){
    sortedRentalArray.sort((a, b) => a.expires - b.expires)
  } else if(e.target.value == "Ending Latest"){
    sortedRentalArray.sort((a, b) => b.expires - a.expires)
  } else if(e.target.value == "Receiver a-z"){
    sortedRentalArray.sort((a, b) => a.rent_to_account.localeCompare(b.rent_to_account));
  } else if(e.target.value == "Receiver z-a"){
    sortedRentalArray.sort((a, b) => b.rent_to_account.localeCompare(a.rent_to_account));
  } else if(e.target.value == "Most WAX"){
    sortedRentalArray.sort((a, b) => Number(b.amount_staked.split(" ")[0]) - Number(a.amount_staked.split(" ")[0]))
  } else if(e.target.value == "Least WAX"){
    sortedRentalArray.sort((a, b) => Number(a.amount_staked.split(" ")[0]) - Number(b.amount_staked.split(" ")[0]))
  }

  setRentals(sortedRentalArray)
  return
}

const calculateDaysToRent = (epoch) => {
  let endTime = epoch + 60 * 60 * 24 * 11;

  const now = new Date();
  const timestamp = Math.floor(now.getTime() / 1000);

  if (timestamp > endTime) {
    console.log("timestamp is: " + timestamp);
    console.log("endtime is: " + endTime);
    return 0;
  }

  return Number((endTime - timestamp) / (60 * 60 * 24)).toFixed(2);
};

export const calculateRentalCost = (
  stateIsLoading,
  fusionState,
  amountToRequest,
  epochToStakeIn,
  configIsLoading,
  fusionConfig
) => {
  if (
    stateIsLoading ||
    !amountToRequest ||
    Number(amountToRequest) <= 0 ||
    epochToStakeIn == "" ||
    configIsLoading
  ) {
    return 0;
  }

  const now = new Date();
  const timestamp = Math.floor(now.getTime() / 1000);

  let eleven_days = 60 * 60 * 24 * 11;
  let seconds_into_current_epoch =
    timestamp - Number(fusionState?.last_epoch_start_time);
  let days_into_current_epoch = seconds_into_current_epoch / (60 * 60 * 24);
  let days_to_rent;

  if (
    epochToStakeIn ==
    fusionState?.last_epoch_start_time + fusionConfig?.seconds_between_epochs
  ) {
    //renting from epoch 3 (hasnt started yet)
    days_to_rent = 18 - days_into_current_epoch;
  } else if (epochToStakeIn == fusionState?.last_epoch_start_time) {
    //renting from epoch 2 (started most recently)
    days_to_rent = 11 - days_into_current_epoch;
  } else if (
    epochToStakeIn ==
    fusionState?.last_epoch_start_time - fusionConfig?.seconds_between_epochs
  ) {
    //renting from epoch 1 (oldest) and we need to make sure it's less than 11 days old

    days_to_rent =
      4 - days_into_current_epoch < 1 ? 1 : 4 - days_into_current_epoch;
  }

  return (
    Number(amountToRequest) *
    Number(fusionState?.cost_to_rent_1_wax.split(" ")[0]) *
    days_to_rent
  ).toFixed(8);
};

const showYouWillSpend = (
  stateIsLoading,
  fusionState,
  amountToRequest,
  epochToStakeIn,
  configIsLoading,
  fusionConfig
) => {
  return (
    <span>
      {calculateRentalCost(
        stateIsLoading,
        fusionState,
        amountToRequest,
        epochToStakeIn,
        configIsLoading,
        fusionConfig
      )}{" "}
      WAX
    </span>
  );
};

export const showRentalPool = (state, stateIsLoading) => {
  if (stateIsLoading) return <span>0</span>;

  let poolBalance = 0;

  if (state?.wax_available_for_rentals) {
    poolBalance = Number(
      state?.wax_available_for_rentals.split(" ")[0]
    ).toFixed(2);
    poolBalance = parseFloat(poolBalance);
  }

  return [<span>{poolBalance}</span>, poolBalance];
};

const Rent = () => {
  const {
    wharfSession,
    setWharfSession,
    setCurrentUser,
    fusionState,
    stateIsLoading,
    tokenBalances,
    balancesAreLoading,
    refresh,
    setRefresh,
    showTxModal,
    setShowTxModal,
    txModalText,
    setTxModalText,
    txIsLoading,
    setTxIsLoading    
  } = useStateContext();

  const [amountToRequest, setAmountToRequest] = useState("");
  const [receiver, setReceiver] = useState("");
  const [refreshRentals, setRefreshRentals] = useState(false);
  const [currentTab, setCurrentTab] = useState("New Rental");

  //custom hooks
  const [fusionConfig, getFusionConfig, configIsLoading] = useGetFusionState();
  const [epochToStakeIn, setEpochToStakeIn] = useState("");
  const [rentals, getRentals, rentalsAreLoading, setRentals] = useGetMyShortTermRentals();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getFusionConfig();

      if (isLoggedIn() && receiver == "") {
        setReceiver(currentUsername);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [refresh]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (
        !configIsLoading &&
        fusionConfig &&
        !stateIsLoading &&
        fusionState &&
        (rentals?.length == 0 || refreshRentals)
      ) {
        let epoch1 =
          fusionState?.last_epoch_start_time +
          fusionConfig?.seconds_between_epochs;
        let epoch2 = fusionState?.last_epoch_start_time;
        let epoch3 =
          fusionState?.last_epoch_start_time -
          fusionConfig?.seconds_between_epochs;

        getRentals([epoch1, epoch2, epoch3]);
        setRefreshRentals(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    refreshRentals,
    fusionConfig,
    configIsLoading,
    fusionState,
    stateIsLoading,
  ]);

  return (
    <div>
      {showTxModal && (
        <TransactionModal
          setShowTxModal={setShowTxModal}
          txModalText={txModalText}
          txIsLoading={txIsLoading}
        />
      )}      
      <PageWrapper2024>
        <PageBody2024>
          <HeaderCont2024>
            <div>
              <h2>CPU Rental</h2>
            </div>

            <div>
              <h3>Rent CPU at the lowest price on the market.</h3>
            </div>
          </HeaderCont2024>

          <FoldersContainer2024>
            <FoldersRowCentered>
              <FolderTab2024
                selected={currentTab == "New Rental"}
                disabled={currentTab == "New Rental"}
                onClick={() => {
                  setCurrentTab("New Rental");
                }}
              >
                New Rental
              </FolderTab2024>

              <FolderTab2024
                selected={currentTab == "My Rentals"}
                disabled={currentTab == "My Rentals"}
                onClick={() => {
                  setCurrentTab("My Rentals");
                }}
              >
                My Rentals
              </FolderTab2024>
            </FoldersRowCentered>
          </FoldersContainer2024>

          <ShowPriceContainer>
            <span>
              For each full day of renting 1 WAX, the current price is{" "}
              <b>
                &nbsp;
                {stateIsLoading
                  ? "0.00000000 WAX"
                  : fusionState?.cost_to_rent_1_wax}{" "}
                ({showRentalCostPercentage(stateIsLoading, fusionState)}%)
              </b>
              .
              {currentTab == "My Rentals" && (
                <span>
                  <br />
                  For short term rentals, you can stake more WAX to an account
                  by using the New Rental option.
                </span>
              )}
            </span>
          </ShowPriceContainer>

          {currentTab == "New Rental" && (
            <StakeContainer>
              <SpaceBetweenDiv>
                <p>Enter Amount</p>
                <ClickableP
                  tabIndex={0}
                  onClick={() => {
                    setAmountToRequest(
                      showWaxBalance(tokenBalances, balancesAreLoading)[1]
                    );
                  }}
                >
                  Max {showWaxBalance(tokenBalances, balancesAreLoading)[0]}
                </ClickableP>
              </SpaceBetweenDiv>
              <FusionInputWrapper wide={true}>
                <input
                  placeholder="WAX amount to rent"
                  value={amountToRequest}
                  onChange={(e) => {
                    setAmountToRequest(e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
              </FusionInputWrapper>
              <br />
              <SpaceBetweenDiv>
                <p>Minimum Rental</p>
                <p>10 WAX</p>
              </SpaceBetweenDiv>
              <SpaceBetweenDiv>
                <p>Protocol Funds</p>
                <p>{showRentalPool(fusionState, stateIsLoading)[0]} WAX</p>
              </SpaceBetweenDiv>

              <br />
              <SpaceBetweenDiv>
                <p>CPU Receiver</p>
                <p></p>
              </SpaceBetweenDiv>
              <FusionInputWrapper wide={true}>
                <input
                  placeholder="e.g. abcde.wam"
                  value={receiver}
                  onChange={(e) => {
                    setReceiver(e.target.value.replace(/[^a-z1-5.]/g, ""));
                  }}
                  maxLength={12}
                />
              </FusionInputWrapper>

              <MessageWrapper>
                <span>
                  Choose how long you want to rent CPU for by selecting one of
                  the options below. To learn more about rental epochs and how
                  the timeframes work, please visit our{" "}
                  <a href={`${config.URLs.docs}/epochs`} target="none">
                    documentation
                  </a>
                  .
                </span>
              </MessageWrapper>
              <br />

              {!stateIsLoading && fusionState && (
                <FusionInputWrapper wide={true}>
                  <select
                    onChange={(e) => {
                      setEpochToStakeIn(Number(e.target.value));
                    }}
                  >
                    <option hidden>Choose Epoch</option>
                    {calculateDaysToRent(
                      Number(fusionState.last_epoch_start_time) - 86400 * 7
                    ) >= 1 && (
                      <option
                        value={
                          Number(fusionState.last_epoch_start_time) - 86400 * 7
                        }
                      >
                        {calculateDaysToRent(
                          Number(fusionState.last_epoch_start_time) - 86400 * 7
                        )}{" "}
                        Day Rental
                      </option>
                    )}
                    <option value={fusionState.last_epoch_start_time}>
                      {calculateDaysToRent(fusionState.last_epoch_start_time)}{" "}
                      Day Rental
                    </option>
                    <option
                      value={
                        Number(fusionState.last_epoch_start_time) + 86400 * 7
                      }
                    >
                      {calculateDaysToRent(
                        Number(fusionState.last_epoch_start_time) + 86400 * 7
                      )}{" "}
                      Day Rental
                    </option>
                  </select>
                </FusionInputWrapper>
              )}

              <br />
              <SpaceBetweenDiv>
                <p>You will spend</p>
                <p>
                  {showYouWillSpend(
                    stateIsLoading,
                    fusionState,
                    amountToRequest,
                    epochToStakeIn,
                    configIsLoading,
                    fusionConfig
                  )}
                </p>
              </SpaceBetweenDiv>

              {isLoggedIn() ? (
                <button
                  onClick={() => {
                    rentCpu(
                      setRefresh,
                      setRefreshRentals,
                      amountToRequest,
                      calculateRentalCost(
                        stateIsLoading,
                        fusionState,
                        amountToRequest,
                        epochToStakeIn,
                        configIsLoading,
                        fusionConfig
                      ),
                      receiver,
                      epochToStakeIn,
                      setShowTxModal,
                      setTxModalText,
                      setTxIsLoading,
                      wharfSession
                    );
                  }}
                >
                  Rent Now
                </button>
              ) : (
                <button
                  onClick={() => {
                    logInWithWharfkit(setCurrentUser, setWharfSession);
                  }}
                >
                  Connect Wallet
                </button>
              )}
            </StakeContainer>
          )}

          {currentTab == "My Rentals" && (
            <>
              {rentalsAreLoading && (
                <MessageWrapper>Rentals are loading...</MessageWrapper>
              )}
              {!rentalsAreLoading && rentals && rentals.length == 0 && (
                <MessageWrapper>You have no existing rentals.</MessageWrapper>
              )}
              {!rentalsAreLoading && rentals && rentals.length > 0 && (
                <>
                <StakeContainer padding={"10px"} paddingBottom={"10px"}>
                <FusionInputWrapper wide={true}>
                  <select
                    onChange={(e) => {
                      handleSortingRentals(e, rentals, setRentals)
                    }}
                  >
                    <option hidden>Sort By</option>
                    {sortMethods.map((method, index) => (
                      <option key={index}
                      value={
                        method
                      }
                    >
                      {method}
                    </option>                      
                      ))}
                  </select>
                </FusionInputWrapper>                  
                </StakeContainer>
                  {rentals.map((item, index) => (
                    <StakeContainer key={index} padding={"10px"} paddingBottom={"10px"}>
                      <MessageWrapper top={"0px"} height={"40px"}>
                        Renting
                        <b>
                          &nbsp;{item.amount_staked?.split(".")[0]} WAX&nbsp;
                        </b>
                        to<b>&nbsp;{item.rent_to_account}&nbsp;</b>
                        until{" "}
                        <b>
                          &nbsp;
                          {new Date(item.expires * 1000).toLocaleDateString()}
                        </b>
                      </MessageWrapper>
                    </StakeContainer>
                  ))}
                </>
              )}
            </>
          )}
        </PageBody2024>
      </PageWrapper2024>
    </div>
  );
};

export default Rent;
