import React from "react";
import {
  FolderTab2024,
  FoldersContainer2024,
  HeaderCont2024,
  PageBody2024,
  PageWrapper2024,
  RentalFolderTab,
} from "../Styles2024";
import {
  BigBlueButton,
  ClickableP,
  FoldersRowCentered,
  FusionInputWrapper,
  MessageWrapper,
  QuickSelectWrapper,
  RentalDetailsWrapper,
  RentalWrapper,
  ShowDetailsButton,
  ShowPriceContainer,
  SpaceBetweenDiv,
  StakeContainer,
} from "../FusionStyles";
import { useState } from "react";
import { useEffect } from "react";
import { isLoggedIn } from "../data/functions/wallet_functions";
import { useStateContext } from "../contexts/ContextProvider";
import { logInWithWharfkit } from "../data/wharfkit";
import {
  extendPolRental,
  increasePolRental,
  rentFromPOL,
} from "../data/functions/fusion_functions";
import { currentUsername } from "../data/config";
import { showWaxBalance } from "./Stake";
import { useGetPolState } from "../components/CustomHooks/useGetPolState";
import { useGetMyPolRentals } from "../components/CustomHooks/useGetMyPolRentals";
import { handleSortingRentals, sortMethods } from "./Rent";
import TransactionModal from "../components/TransactionModal";

const calculateDaysRemaining = (endTime) => {
  const now = new Date();
  const timestamp = Math.floor(now.getTime() / 1000);

  if (timestamp > endTime) {
    return 0;
  }

  return Number((endTime - timestamp) / (60 * 60 * 24));
};

export const calculateRentalCost = (
  stateIsLoading,
  polState,
  amountToRequest,
  daysToRent
) => {
  if (
    stateIsLoading ||
    !amountToRequest ||
    Number(amountToRequest) <= 0 ||
    !daysToRent ||
    Number(daysToRent) <= 0
  ) {
    return 0;
  }

  return (
    Number(amountToRequest) *
    Number(polState?.cost_to_rent_1_wax.split(" ")[0]) *
    daysToRent
  ).toFixed(8);
};

export const showRentalCostPercentage = (stateIsLoading, polState) => {
  if (stateIsLoading) {
    return "0.00";
  }

  return Number(polState?.cost_to_rent_1_wax.split(" ")[0] * 100);
};

const showYouWillSpend = (
  stateIsLoading,
  polState,
  amountToRequest,
  daysToRent
) => {
  return (
    <span>
      {calculateRentalCost(
        stateIsLoading,
        polState,
        amountToRequest,
        daysToRent
      )}{" "}
      WAX
    </span>
  );
};

const showRentalPool = (state, stateIsLoading) => {
  if (stateIsLoading) return <span>0</span>;

  let poolBalance = 0;

  if (state?.wax_available_for_rentals) {
    poolBalance = Number(
      state?.wax_available_for_rentals.split(" ")[0]
    ).toFixed(2);
  }

  return <span>{poolBalance}</span>;
};

const LongTermRent = () => {
  const {
    wharfSession,
    setWharfSession,
    setCurrentUser,
    tokenBalances,
    balancesAreLoading,
    refresh,
    setRefresh,
    showTxModal,
    setShowTxModal,
    txModalText,
    setTxModalText,
    txIsLoading,
    setTxIsLoading      
  } = useStateContext();

  const [currentTab, setCurrentTab] = useState("New Rental");

  const [amountToRequest, setAmountToRequest] = useState("");
  const [receiver, setReceiver] = useState("");
  const [daysToRent, setDaysToRent] = useState("");
  const [daysToExtend, setDaysToExtend] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentRentalTab, setCurrentRentalTab] = useState("Extend");
  const [waxToAdd, setWaxToAdd] = useState("");

  //custom hooks
  const [rentals, getRentals, rentalsAreLoading, setRentals] =
    useGetMyPolRentals();
  const [polState, getPolState, stateIsLoading] = useGetPolState();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getPolState();

      if (isLoggedIn()) {
        setReceiver(currentUsername);
      }

      getRentals();
    }

    return () => {
      isMounted = false;
    };
  }, [refresh]);

  return (
    <div>
      {showTxModal && (
        <TransactionModal
          setShowTxModal={setShowTxModal}
          txModalText={txModalText}
          txIsLoading={txIsLoading}
        />
      )}        
      <PageWrapper2024>
        <PageBody2024>
          <HeaderCont2024>
            <div>
              <h2>Long Term CPU Rental</h2>
            </div>

            <div>
              <h3>Rent CPU for 1 month or longer.</h3>
            </div>
          </HeaderCont2024>

          <FoldersContainer2024>
            <FoldersRowCentered>
              <FolderTab2024
                selected={currentTab == "New Rental"}
                disabled={currentTab == "New Rental"}
                onClick={() => {
                  setCurrentTab("New Rental");
                }}
              >
                New Rental
              </FolderTab2024>

              <FolderTab2024
                selected={currentTab == "My Rentals"}
                disabled={currentTab == "My Rentals"}
                onClick={() => {
                  setCurrentTab("My Rentals");
                }}
              >
                My Rentals
              </FolderTab2024>
            </FoldersRowCentered>
          </FoldersContainer2024>

          <ShowPriceContainer>
            <span>
              For each full day of renting 1 WAX, the current price is{" "}
              <b>
                &nbsp;
                {stateIsLoading
                  ? "0.00000000 WAX"
                  : polState?.cost_to_rent_1_wax}{" "}
                ({showRentalCostPercentage(stateIsLoading, polState)}%)
              </b>
            </span>
          </ShowPriceContainer>

          {currentTab == "New Rental" && (
            <StakeContainer>
              <SpaceBetweenDiv>
                <p>Enter Amount</p>
                <ClickableP
                  tabIndex={0}
                  onClick={() => {
                    setAmountToRequest(
                      showWaxBalance(tokenBalances, balancesAreLoading)[1]
                    );
                  }}
                >
                  Max {showWaxBalance(tokenBalances, balancesAreLoading)[0]}
                </ClickableP>
              </SpaceBetweenDiv>
              <FusionInputWrapper wide={true}>
                <input
                  placeholder="WAX amount to rent"
                  value={amountToRequest}
                  onChange={(e) => {
                    setAmountToRequest(e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
              </FusionInputWrapper>
              <br />
              <SpaceBetweenDiv>
                <p>Minimum Rental</p>
                <p>10 WAX</p>
              </SpaceBetweenDiv>
              <SpaceBetweenDiv>
                <p>Protocol Funds</p>
                <p>{showRentalPool(polState, stateIsLoading)} WAX</p>
              </SpaceBetweenDiv>

              <br />
              <SpaceBetweenDiv>
                <p>CPU Receiver</p>
                <p></p>
              </SpaceBetweenDiv>
              <FusionInputWrapper wide={true}>
                <input
                  placeholder="e.g. abcde.wam"
                  value={receiver}
                  onChange={(e) => {
                    setReceiver(e.target.value.replace(/[^a-z1-5.]/g, ""));
                  }}
                  maxLength={12}
                />
              </FusionInputWrapper>

              <br />
              <SpaceBetweenDiv>
                <p>Days To Rent</p>
                <p>Min 30</p>
              </SpaceBetweenDiv>
              <FusionInputWrapper wide={true}>
                <input
                  placeholder="e.g. 90"
                  value={daysToRent}
                  onChange={(e) => {
                    setDaysToRent(e.target.value.replace(/[^0-9]/g, ""));
                  }}
                  maxLength={4}
                />
              </FusionInputWrapper>

              <QuickSelectWrapper>
                <button
                  onClick={() => {
                    setDaysToRent(30);
                  }}
                >
                  30d
                </button>
                <button
                  onClick={() => {
                    setDaysToRent(90);
                  }}
                >
                  90d
                </button>
                <button
                  onClick={() => {
                    setDaysToRent(180);
                  }}
                >
                  180d
                </button>
                <button
                  onClick={() => {
                    setDaysToRent(365);
                  }}
                >
                  365d
                </button>
              </QuickSelectWrapper>

              <br />
              <SpaceBetweenDiv>
                <p>You will spend</p>
                <p>
                  {showYouWillSpend(
                    stateIsLoading,
                    polState,
                    amountToRequest,
                    daysToRent
                  )}
                </p>
              </SpaceBetweenDiv>

              {isLoggedIn() ? (
                <button
                  onClick={() => {
                    rentFromPOL(
                      setRefresh,
                      amountToRequest,
                      calculateRentalCost(
                        stateIsLoading,
                        polState,
                        amountToRequest,
                        daysToRent
                      ),
                      receiver,
                      daysToRent,
                      setShowTxModal,
                      setTxModalText,
                      setTxIsLoading,
                      wharfSession
                    );
                  }}
                >
                  Rent Now
                </button>
              ) : (
                <button
                  onClick={() => {
                    logInWithWharfkit(setCurrentUser, setWharfSession);
                  }}
                >
                  Connect Wallet
                </button>
              )}
            </StakeContainer>
          )}

          {currentTab == "My Rentals" && (
            <>
              {rentalsAreLoading && (
                <MessageWrapper>Rentals are loading...</MessageWrapper>
              )}
              {!rentalsAreLoading && rentals && rentals.length == 0 && (
                <MessageWrapper>You have no existing rentals.</MessageWrapper>
              )}
              {!rentalsAreLoading && rentals && rentals.length > 0 && (
                <>
                  <StakeContainer padding={"10px"} paddingBottom={"10px"}>
                    <FusionInputWrapper wide={true}>
                      <select
                        onChange={(e) => {
                          handleSortingRentals(e, rentals, setRentals);
                        }}
                      >
                        <option hidden>Sort By</option>
                        {sortMethods.map((method, index) => (
                          <option key={index} value={method}>
                            {method}
                          </option>
                        ))}
                      </select>
                    </FusionInputWrapper>
                  </StakeContainer>

                  {rentals.map((item, index) => (
                    <RentalWrapper
                      key={index}
                      show={currentIndex == index}
                      rentalTab={currentRentalTab}
                    >
                      <MessageWrapper top={"5px"} height={"40px"}>
                        Renting
                        <b>
                          &nbsp;{item.amount_staked?.split(".")[0]} WAX&nbsp;
                        </b>
                        to<b>&nbsp;{item.rent_to_account}&nbsp;</b>
                      </MessageWrapper>

                      <ShowDetailsButton
                        onClick={() => {
                          if (currentIndex == index) {
                            setCurrentIndex(-1);
                          } else {
                            setCurrentIndex(index);
                          }
                        }}
                      >
                        {currentIndex == index
                          ? "Hide Details"
                          : "Show Details"}
                      </ShowDetailsButton>
                      <RentalDetailsWrapper
                        show={currentIndex == index}
                        rentalTab={currentRentalTab}
                      >
                        <br />
                        <SpaceBetweenDiv>
                          <p>Expires</p>
                          <p></p>
                        </SpaceBetweenDiv>
                        <FusionInputWrapper wide={true}>
                          <input
                            disabled
                            value={new Date(
                              item.expires * 1000
                            ).toLocaleDateString()}
                          />
                        </FusionInputWrapper>

                        <FoldersContainer2024>
                          <FoldersRowCentered>
                            <RentalFolderTab
                              selected={currentRentalTab == "Extend"}
                              disabled={currentRentalTab == "Extend"}
                              onClick={() => {
                                setCurrentRentalTab("Extend");
                              }}
                            >
                              Extend
                            </RentalFolderTab>

                            <RentalFolderTab
                              selected={currentRentalTab == "Increase"}
                              disabled={currentRentalTab == "Increase"}
                              onClick={() => {
                                setCurrentRentalTab("Increase");
                              }}
                            >
                              Increase
                            </RentalFolderTab>
                          </FoldersRowCentered>
                        </FoldersContainer2024>
                        <br />

                        {currentRentalTab == "Extend" && (
                          <>
                            <SpaceBetweenDiv>
                              <p>Days To Extend</p>
                              <p></p>
                            </SpaceBetweenDiv>
                            <FusionInputWrapper wide={true}>
                              <input
                                placeholder="e.g. 30"
                                value={daysToExtend}
                                onChange={(e) => {
                                  setDaysToExtend(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  );
                                }}
                                maxLength={4}
                              />
                            </FusionInputWrapper>
                            <QuickSelectWrapper>
                              <button
                                onClick={() => {
                                  setDaysToExtend(30);
                                }}
                              >
                                30d
                              </button>
                              <button
                                onClick={() => {
                                  setDaysToExtend(90);
                                }}
                              >
                                90d
                              </button>
                              <button
                                onClick={() => {
                                  setDaysToExtend(180);
                                }}
                              >
                                180d
                              </button>
                              <button
                                onClick={() => {
                                  setDaysToExtend(365);
                                }}
                              >
                                365d
                              </button>
                            </QuickSelectWrapper>
                            <br />
                            <SpaceBetweenDiv>
                              <p>Your Balance</p>
                              <p>
                                {
                                  showWaxBalance(
                                    tokenBalances,
                                    balancesAreLoading
                                  )[0]
                                }{" "}
                                WAX
                              </p>
                            </SpaceBetweenDiv>
                            <SpaceBetweenDiv>
                              <p>You will spend</p>
                              <p>
                                {showYouWillSpend(
                                  stateIsLoading,
                                  polState,
                                  item.amount_staked.split(" ")[0],
                                  daysToExtend
                                )}
                              </p>
                            </SpaceBetweenDiv>

                            {isLoggedIn() ? (
                              <BigBlueButton
                                onClick={() => {
                                  extendPolRental(
                                    setRefresh,
                                    item.amount_staked.split(" ")[0],
                                    calculateRentalCost(
                                      stateIsLoading,
                                      polState,
                                      item.amount_staked.split(" ")[0],
                                      daysToExtend
                                    ),
                                    item.rent_to_account,
                                    daysToExtend,
                                    setShowTxModal,
                                    setTxModalText,
                                    setTxIsLoading,
                                    wharfSession
                                  );
                                }}
                              >
                                Extend Now
                              </BigBlueButton>
                            ) : (
                              <BigBlueButton
                                onClick={() => {
                                  logInWithWharfkit(
                                    setCurrentUser,
                                    setWharfSession
                                  );
                                }}
                              >
                                Connect Wallet
                              </BigBlueButton>
                            )}
                          </>
                        )}

                        {currentRentalTab == "Increase" && (
                          <>
                            <SpaceBetweenDiv>
                              <p>Enter Amount</p>
                              <ClickableP
                                tabIndex={0}
                                onClick={() => {
                                  setWaxToAdd(
                                    showWaxBalance(
                                      tokenBalances,
                                      balancesAreLoading
                                    )[1]
                                  );
                                }}
                              >
                                Max{" "}
                                {
                                  showWaxBalance(
                                    tokenBalances,
                                    balancesAreLoading
                                  )[0]
                                }
                              </ClickableP>
                            </SpaceBetweenDiv>
                            <FusionInputWrapper wide={true}>
                              <input
                                placeholder="WAX To Add"
                                value={waxToAdd}
                                onChange={(e) => {
                                  setWaxToAdd(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  );
                                }}
                                maxLength={8}
                              />
                            </FusionInputWrapper>
                            <br />
                            <SpaceBetweenDiv>
                              <p>Minimum Increase</p>
                              <p>100 WAX</p>
                            </SpaceBetweenDiv>
                            <SpaceBetweenDiv>
                              <p>Protocol Funds</p>
                              <p>
                                {showRentalPool(polState, stateIsLoading)} WAX
                              </p>
                            </SpaceBetweenDiv>
                            <SpaceBetweenDiv>
                              <p>You will spend</p>
                              <p>
                                {showYouWillSpend(
                                  stateIsLoading,
                                  polState,
                                  waxToAdd,
                                  calculateDaysRemaining(item.expires)
                                )}
                              </p>
                            </SpaceBetweenDiv>

                            {isLoggedIn() ? (
                              <BigBlueButton
                                onClick={() => {
                                  increasePolRental(
                                    setRefresh,
                                    waxToAdd,
                                    calculateRentalCost(
                                      stateIsLoading,
                                      polState,
                                      waxToAdd,
                                      calculateDaysRemaining(item.expires)
                                    ),
                                    item.rent_to_account,
                                    setShowTxModal,
                                    setTxModalText,
                                    setTxIsLoading,
                                    wharfSession
                                  );
                                }}
                              >
                                Increase Now
                              </BigBlueButton>
                            ) : (
                              <BigBlueButton
                                onClick={() => {
                                  logInWithWharfkit(
                                    setCurrentUser,
                                    setWharfSession
                                  );
                                }}
                              >
                                Connect Wallet
                              </BigBlueButton>
                            )}
                          </>
                        )}
                      </RentalDetailsWrapper>
                    </RentalWrapper>
                  ))}
                </>
              )}
            </>
          )}
        </PageBody2024>
      </PageWrapper2024>
    </div>
  );
};

export default LongTermRent;
